.wrapper {
	display: flex;
	flex-direction: row;
	justify-content: space-around;
	align-items: center;
	padding: 0 15%;
	width: 100%;
}

.line {
	flex: 1;
	height: 2px;
	background-color: #000000;
	opacity: 0.25;
}

.blueOceanLogo {
	margin: 15px 15px 28px;
}
