.dockingContainer{
    position: absolute;
    top: calc(50% - 96px);
    left: calc(50% - 112px);
    width: 224px;
    height: 195px;
    background: #1E1F22 0% 0% no-repeat padding-box;
    box-shadow: 0px 2px 10px #00000029;
    border-radius: 10px;
    opacity: 0.9;
    z-index: 10;
    color: white;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    text-align: center;
    padding: 10px 20px 10px 20px;
    .dockingTitle{
     font: normal normal normal 18px/21px Industry;
     letter-spacing: 0px;
     color: #56AE4D;
     opacity: 1;
    }
    .dockingStatus{
       text-align: center;
       font: normal normal normal 15px/20px Roboto;
       letter-spacing: 0px;
       color: #FFFFFF;
       opacity: 1;
    }
    .dockingIconWrapper{
        width: 45px;
        height: 55px;
        img{
            width: 100%;
            height: 100%;
        }
    }
    .dockingNote{
        text-align: center; 
        font: normal normal normal 12px/16px Roboto;
        letter-spacing: 0px;
        color: #FFFFFF;
        span{
         color: #56AE4D;
        }
    }
}