.failedSessionConnectionContainer {
	top: calc(100vh - 275px);
	left: 30px;
	width: 396px;
	height: 150px;
	opacity: 0.95;
	background-color: #1e1f22;
	position: absolute;
	z-index: 100;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	color: white; 
	border-radius: 10px;
	padding: 20px 0 30px 20px;
	.headingContainer{
		display: flex;
		justify-content: start;
		align-items: center;
		width: 100%;
		text-align: center;
		font-size: 26px;
		.closeCircleIconWrapper{
			width: 25px;
			height: 25px;
			position: absolute;
            left: 360px;
            bottom: 110px;
			img{
				cursor: pointer;
	
			}
		}
		.impairedHeading{
			color: #56AE4D;
			text-align: center;
			font-family:Industry;
			letter-spacing: 0px;
			color: #56AE4D;
			opacity: 1;
			padding-top: 6px;
		}
		.warningIconWrapper{
			width: 25px;
			height: 25px;
			margin-right: 6px;
			img{
				width: 100%;
	            height: 100%;
			}
		}
	}
	.textSessionConnectionContainer{
		text-align: left;
		width: 100%;
		padding-top: 12px;
	}
}

.failedSessionConnectionContainer:hover{
	display: flex !important;
}

.failedSessionConnectionCircle{
	top: calc(100vh - 175px);
    left: 30px;
    width: 50px;
    height: 50px;
	border-radius: 50px;
	opacity: 0.5;
	background-color: #1e1f22;
	position: absolute;
	z-index: 100;
	justify-content: center;
	align-items: center;
	display: flex;
	flex-direction: column;
	cursor: pointer;
	.warningIconWrapper{
		width: 25px;
		height: 25px;
		img{
			width: 100%;
			height: 100%;
		}
	}
}

.failedSessionConnectionCircle:hover{
	display: flex !important;
}

.failedShowAbsoluteMenu{
	left: 110px !important;
}

.failedShowAbsoluteMenuExpand{
	left: 290px !important;
}

.failedSessionConnectionCircle:hover{ 
	opacity: 0.95;
}

.displayNone{
	display: none;
}