body {
    margin: 0;
    height: 100%;
    font-size: 100%;
    font-family: "Roboto", sans-serif;
    color: #1e1f22;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
      monospace;
  }
  
  br {
    display: block;
  }
  
  @font-face {
    font-family: "Industry-Light";
    font-style: normal;
    font-weight: 300;
    font-display: swap;
    src: url("./themeMui/fonts/assets/Industry/Industry-Light.woff") format("woff");
  }
  
  @font-face {
    font-family: "Industry-Medium";
    font-style: normal;
    font-weight: 500;
    font-display: swap;
    src: url("./themeMui/fonts/assets/Industry/Industry-Medium.woff") format("woff");
  }
  
  @font-face {
    font-family: "Industry-Demi";
    font-style: normal;
    font-weight: 600;
    font-display: swap;
    src: url("./themeMui/fonts/assets/Industry/Industry-Demi.woff") format("woff");
  }
  
  @font-face {
    font-family: "Industry-Bold";
    font-style: normal;
    font-weight: 700;
    font-display: swap;
    src: url("./themeMui/fonts/assets/Industry/Industry-Bold.woff") format("woff");
  }
  